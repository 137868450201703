import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import MenuTile from '~components/MenuTile'
import ArticleTile from '~components/ArticleTile'
import { Link } from 'gatsby'
import resolveLink from '~utils/resolveLink'
import Image from '~components/Image'
import { useSiteState } from '~context/siteContext'
import MenuTagTile from '~components/MenuTagTile'
import SanityLink from '~components/SanityLink'

const Menu = ({ className, view, menuData }) => {
	
	const aZ = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '#']

	return (
		<Wrap className={className}>
			{view === 'directory' && 
				<MenuGrid>
					<Categories>
					<MenuHeading className='caps'>Categories</MenuHeading>
						{menuData?.featuredCollections?.map(button => (
							<MenuLink 
								key={button.text} 
								className='small'
								link={button.link}
							>{button.text}</MenuLink>
						))}
						{menuData?.featuredCollectionsBelow && <Line />}
						{menuData?.featuredCollectionsBelow?.map(button => (
							<MenuLink 
								key={button.text} 
								className='small'
								link={button.link}
							>{button.text}</MenuLink>
						))}
					</Categories>
					<MenuDivider />
					<BrandsAZ>
						<MenuHeading className='caps'>Brands A – Z</MenuHeading>
						<AZGrid>
							{aZ.map(letter => (
								<AZLink key={letter} className='small' to={`/a-z#${letter === '#' ? 'hash' : letter}`}>{letter}</AZLink>
							))}
						</AZGrid>
						<AllAZ to={'/a-z'}>
							View All
						</AllAZ>
					</BrandsAZ>
					<MenuDividerCenter />
					<FeaturedBrands>
						<MenuHeading className='caps'>Featured Brands</MenuHeading>
						<FeaturedBrandsGrid>
							{menuData?.featuredBrands?.map(brand => (
								<MenuTile key={brand._id} content={brand} link={{linkType: 'internal', document: brand}}/>
							))}
						</FeaturedBrandsGrid>
					</FeaturedBrands>
					<MenuDividerCenter />
					<BrandEdits>
						<MenuHeading className='caps'>Brand Edits</MenuHeading>
						<BrandEditsGrid>
							<BrandEditTile content={menuData?.featuredBrandEdits[0]} link={menuData?.featuredBrandEdits[0].link}/>
							<BrandEditLinks>
								{menuData?.featuredBrandEdits.slice(1).map(edit => (
									<CircleLink key={edit.title} link={edit.link} className='small'>
										<CircleImage image={edit?.image} aspectRatio={1}/>
										{edit.title}
									</CircleLink>
								))}
							</BrandEditLinks>
						</BrandEditsGrid>
					</BrandEdits>
				</MenuGrid>
			}
			{menuData?.menuItems?.map(item => (
				<React.Fragment key={item._key}>
					{view === item.title.toLowerCase() &&
						<MenuGrid>
							<MenuHeadingWide className='caps'>{item.title}</MenuHeadingWide>
							{item?.subItems?.slice(0,8).map(subItem => (
								<MenuItemTile 
								content={subItem} 
								key={subItem._key}/>
							))
							}
						</MenuGrid>
					}
				</React.Fragment>
			))}
			{view === 'focus' && 
				<MenuGrid>
					<Inclusivity>
						<MenuHeading className='caps'>Inclusive</MenuHeading>
						{menuData.featuredInclusivity?.map(button => (
							<MenuLink key={button.text} className='small' link={button.link}>{button.text}</MenuLink>
						))}
					</Inclusivity>
					<MenuDividerCenter />
					<Values>
						<ValuesTitle className='caps'>Initiatives</ValuesTitle>
						{menuData.featuredValues?.map(tile => (
							<TagTile key={tile.title} content={tile} aspectRatio={1.19}/>
						))}
					</Values>
					<MenuDividerCenter />
					<Interest>
						<MenuHeading className='caps'>Interest</MenuHeading>
						{menuData.featuredInterest?.map(tile => (
							<CircleLink key={tile.slug?.current} link={tile.link} className='small'>
								<CircleImage image={tile?.image} aspectRatio={1}/>
								{tile.title}
							</CircleLink>
						))}
					</Interest>
				</MenuGrid>
			}
			{view === 'journal' && 
				<MenuGrid>
					<Explore>
						<MenuHeading className='caps'>Explore</MenuHeading>
						{menuData.featuredColumns?.map(doc => (
							<MenuLink key={doc.title} className='small' link={{linkType: 'internal', document: doc}}>{doc.title}</MenuLink>
						))}
						<Link to='/journal' key={'view-all'} className='small'>View All</Link>
					</Explore>
					<Stories>
						<MenuHeading className='caps'>Featured stories</MenuHeading>
						<FeaturedArticles>
						{menuData.featuredArticles.slice(0,8)?.map(tile => (
							<MenuArticleTile key={tile.title} content={tile} aspectRatio={1.19} menu/>
						))}
					</FeaturedArticles>
					</Stories>
				</MenuGrid>
			}
		</Wrap>
	)
}

const Wrap = styled.div`
	padding-top: 50px;
	padding-bottom: 50px;
	box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.1);
`
const MenuGrid = styled(Section)`
	>div{
		grid-template-columns: repeat(16, 1fr);
		grid-column-gap: 25px;
		${mobile}{
			grid-template-columns: 6;
			grid-column-gap: 15px;
		}
	}
`
const MenuHeading = styled.h6`
	margin-bottom: 18px;
	font-weight: 600;
`
const MenuHeadingWide = styled(MenuHeading)`
	grid-column: span 16;
`
const MenuColumn = styled.div`
	display: flex;
	flex-direction: column;
`
const MenuDivider = styled.div`
	grid-column: span 1;
	position: relative;
	&:after{
		content: '';
		background: var(--black);
		height: 100%;
		width: 1px;
		position: absolute;
	}
`
const MenuDividerCenter = styled(MenuDivider)`
	justify-self: center;
`
const Categories = styled(MenuColumn)`
	padding-left: 0px;	
	grid-column: span 2;
`
const MenuLink = styled(SanityLink)`
	margin-bottom: 10px;
	display: block;
`
const BrandsAZ = styled(MenuColumn)`
	grid-column: span 2;
	margin-left: -25px;
`
const AZGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
`
const AZLink = styled(Link)`
	text-transform: uppercase;
	margin-bottom: 10px;
`
const AllAZ = styled(Link)`
	margin-top: auto;
`
const FeaturedBrands = styled(MenuColumn)`
	grid-column: span 4;
`
const BrandEdits = styled(MenuColumn)`
	grid-column: span 5;
	padding-right: 0px;
	border-right: 0px;
`
const BrandEditsGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-column-gap: 25px;
`
const BrandEditTile = styled(MenuTile)`
	grid-column: span 2;
`
const BrandEditLinks = styled.div`
	grid-column: span 3;
`
const FeaturedBrandsGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 25px;
`
const CircleLink = styled(SanityLink)`
	display: grid;
	grid-template-columns: max-content 1fr;
	margin-bottom: 12px;
	align-items: center;
`
const CircleImage = styled(Image)`
	width: 35px;
	margin-right: 13px;
	> div{
		border-radius: 50px;
		overflow: hidden;
	}
`
const MenuItemTile = styled(MenuTile)`
	grid-column: span 2;
`
const Explore = styled.div`
	grid-column: span 4;
`
const FeaturedArticles = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: 40px;
`

const Stories = styled.div`
	grid-column: span 12;
`

const MenuArticleTile = styled(ArticleTile)`
	grid-column: span 3;
`
const Inclusivity = styled.div`
	grid-column: span 3;
`
const Values = styled.div`
	grid-column: span 6;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 25px;
	${mobile}{
		grid-template-columns: 1fr;
	}
`
const ValuesTitle = styled(MenuHeading)`
	grid-column: span 2;
	${mobile}{
		grid-column: span 1;
	}
`
const Interest = styled.div`
	grid-column: span 3;
`
const TagTile = styled(MenuTagTile)`
	margin-bottom: 20px;
`

const Line = styled.div`
	width: 100%;
	height: 1px;
	background: var(--black);
	margin-top: 12px;
	margin-bottom: 22px;
`
Menu.propTypes = {
	className: PropTypes.string
}

export default Menu